import React from "react";
import logo from "./../../../../static/content/logo.svg";
import * as styles from "./Header.scss";
import HeaderMenuBar from "./HeaderMenuBar";
import { UserBoxWithRouter } from "./UserBox";

export default class Header extends React.Component {
  public render() {
    return (
      <div>
        <div className="navbar navbar-default">
          <a href="/" className="navbar-brand">
            <img className={styles.logo} src={logo} alt="Vistaprint Corporate Solutions"/>
          </a>
          <UserBoxWithRouter></UserBoxWithRouter>
        </div>
        <HeaderMenuBar></HeaderMenuBar>
      </div>
      );
    }
  }
