import { NavTab, NavTabItem } from "@cimpress/react-components";
import React from "react";
import { NavLink } from "react-router-dom";
import pageTitleHandler from "../../../providers/PageTitleHandler";

interface IState {
    title: string;
}

export default class HeaderMenuBar extends React.Component<{}, IState> {

    constructor(props: {}) {
        super(props);
        this.state = { title : pageTitleHandler.defaultPageTitle };
        pageTitleHandler.bindUpdateMethod(this.updatePageTitle);
    }

    public render() {
        return(
            <div className="navbar-fluid">
                <div className="navbar navbar-block subtitle">
                    <div className="navbar-header">
                        <h2>{this.state.title}</h2>
                    </div>
                    <div className="fluid-collapse navbar-fluid-collapse navbar-right">
                        <NavTab>
                            <NavTabItem>
                                <NavLink to="/">Tools</NavLink>
                            </NavTabItem>
                            <NavTabItem>
                                <NavLink to="/about">About</NavLink>
                            </NavTabItem>
                            <NavTabItem>
                                <NavLink to="/contact-us">Contact Us</NavLink>
                            </NavTabItem>
                        </NavTab>
                    </div>
                </div>
            </div>
        );
    }

    private updatePageTitle = (title: string) => {
        this.setState({title});
    }
}
