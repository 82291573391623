import { Button, Tooltip } from "@cimpress/react-components";
import React, { CSSProperties } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { WebAuthInstance } from "../../../auth/WebAuthentication";
import { IUserProfile } from "../../../models/IUserProfile";

interface IState {
    showUserActionBox: boolean;
}
export class UserBox extends React.Component<RouteComponentProps, IState> {

    public profile?: IUserProfile;

    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            showUserActionBox: false,
        };
    }

    public render() {

        return (
            <div className="dropdown mcp-navbar-userbox">
                <div className="mcp-navbar-userbox">
                    {this.UserProfile}
                </div>
            </div>
        );
    }

    get UserProfile() {
        try {
            this.profile = WebAuthInstance.getProfile();
            if (this.profile) {
                return this.UserToolBox;
            } else {
                return this.LoginButton;
            }
        } catch {
            return this.LoginButton;
        }

    }

    get LoginButton() {
        return(
            <Button className="btn-outline-secondary" type="default" size="sm" onClick={this.onLoginClicked}>
                Log In
            </Button>
        );
    }

    get UserToolBox() {
        return(
            <Tooltip
                direction="top"
                variety="popover"
                className="user-popover"
                contents={
                    this.UserActionBox
                }
                onClickOutside={() => this.state.showUserActionBox && this.setState({ showUserActionBox: false })}
                show={this.state.showUserActionBox}>
                {this.UserProfileBox}
            </Tooltip>
        );
    }

    get UserActionBox() {
        const userNameStyle: CSSProperties = {
            marginBottom: "5px",
        };
        const userEmailStyle: CSSProperties = {
            marginBottom: "2px",
        };
        return (
            <div className="text-center">
                <div className="popover-content">
                    <img className="avatar" src={this.profile!.picture}></img>
                    <div className="username" style={userNameStyle}>{this.profile!.name}</div>
                    <div className="email" style={userEmailStyle}>{this.profile!.email}</div>
                </div>
                <div className="popover-content">
                    <Button type="primary" blockLevel={true} onClick={this.onLogoutClicked}>
                        Log Out
                    </Button>
                </div>
            </div>
        );
    }

    get UserProfileBox() {
        const tenants: string[] = this.profile!["https://claims.cimpress.io/tenants"];
        return (<a className="clickable"
            onClick={() => this.setState({ showUserActionBox: !this.state.showUserActionBox })}>
            <div>
                <div className="username">{this.profile!.name}</div>
                <div className="organization">{tenants[tenants.length - 1]}</div>
            </div>
            <img className="avatar" src={this.profile!.picture}></img>
        </a>);
    }

    private onLoginClicked = () => {
        const redirectTo = location.pathname;
        WebAuthInstance.login(redirectTo);
    }

    private onLogoutClicked = () => {
        WebAuthInstance.logout();
    }
}

export const UserBoxWithRouter = withRouter(UserBox);
