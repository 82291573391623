import { shapes } from "@cimpress/react-components";
import React, {CSSProperties} from "react";
import { RouteComponentProps } from "react-router-dom";
import IAppConfig from "../../models/IAppConfig";
import IMatchParams from "../../models/IMatchParams";
import AppConfigProvider from "../../providers/AppConfigProvider";
import pageTitleHandler from "../../providers/PageTitleHandler";
import history from "../../utils/history";
import PageNotFound from "../error/PageNotFound";
import "./AppView.scss";

interface IState {
    appNotFound: boolean;
}

export default class View extends React.Component<RouteComponentProps<IMatchParams>, IState> {
    private data: IAppConfig | undefined;

    constructor(props: RouteComponentProps<IMatchParams>) {
        super(props);
        this.state = {
            appNotFound: false,
        };
    }

    public render() {
        const { Spinner } = shapes;
        const spinStyle: CSSProperties = {
            textAlign : "center",
        };
        if (this.state.appNotFound) {
            return(
                <PageNotFound></PageNotFound>
            );
        } else {
            return (
                <div>
                    <div id="view">
                        <div className="container" style={spinStyle}>
                            <Spinner size="large"/>
                        </div>
                    </div>
                </div>
            );
        }
    }

    public componentDidMount() {
        const key = this.props.match.params.appName;
        AppConfigProvider.get(key)
            .then((result) => {
                this.data = result;
                this.renderApp(this.data);
            });
    }

    public componentWillUnmount() {
        pageTitleHandler.updatePageTitle();
        const container =  document.getElementById("view");
        if (container && container.children.length > 0) {
            container.removeChild(container.children[0]);
        }
    }

    private renderApp(data: IAppConfig) {
        if (data) {
            pageTitleHandler.updatePageTitle(data.details.title);
            if (!this.isScriptAlreadyIncluded(data.details.bundleUrl)) {
                const fileref = document.createElement("script");
                fileref.setAttribute("type", "text/javascript");
                fileref.setAttribute("src", data.details.bundleUrl);
                document.body.appendChild(fileref);
            }
            const elem = document.createElement(data.details.element);
            elem.setAttribute("name", data.details.title);
            const container = document.getElementById("view");
            if (container) {
                if (container.children.length > 0) {
                    container.removeChild(container.children[0]);
                }
                container.appendChild(elem);
            }
        } else {
            this.setState({
                ...this.state,
                appNotFound: true,
            });
        }
    }

    private isScriptAlreadyIncluded(src: any): boolean {
        const scripts = document.getElementsByTagName("script");
        for (let i = 0; i < scripts.length; i++) {
            const elem = scripts.item(i) ? scripts.item(i) : undefined;
            if (elem && elem.getAttribute("src") === src) {
                return true;
            }
        }
        return false;
    }
}
