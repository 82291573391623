import React, { Component } from "react";

export default class AccessDenied extends Component {
  public render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <h1>Access Denied.</h1>
          <p>You don't yet have access to this page.</p>
          <p>Email us at{" "}
            <a href="mailto:zerogravity@vistaprint.com?Subject=Internal%20Tools%20Access%20Request"
              target="_top">zerogravity@vistaprint.com</a>.
          </p>
        </div>
      </div>
      );
    }
  }
