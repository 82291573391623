import axios from "axios";
import { AUTH_CONFIG } from "./configuration";
import { WebAuthInstance } from "./WebAuthentication";

interface IUserCoamGroup {
    id: string;
    name: string;
    description: string;
    is_admin: boolean;
}

export const CoamAuthorization = {
    authorizeUser : async () => {
        const userProfile = WebAuthInstance.getProfile();
        if (userProfile) {
            const canonicalId = userProfile["https://claims.cimpress.io/canonical_id"];
            const resp = await axios.get(`${AUTH_CONFIG.coamPrincipalUrl}/${canonicalId}/groups`);
            if (resp.status === 200 && resp.data && resp.data.groups && resp.data.groups.length) {
                const groups: IUserCoamGroup[] = resp.data.groups;
                return !!(groups.filter((i) => {
                    return i.id === AUTH_CONFIG.coamGroupId;
                }).length);
            }
            return false;

        } else { return false; }
    },
};
