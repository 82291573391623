import { Auth0DecodedHash, WebAuth } from "auth0-js";
import history from "../utils/history";
import { IAuth0Authentication } from "./Auth0Authentication";
import { AUTH_CONFIG } from "./configuration";
/**
 * Web based Auth0 authentication
 *
 * @export
 * @class WebAuthentication
 * @implements {IAuth0Authentication}
 */
class WebAuthentication implements IAuth0Authentication {
  /**
   * @property
   * @private
   * @type {WebAuth}
   * @memberof WebAuthenticationManager
   */
  private auth0: WebAuth = new WebAuth({
    audience: AUTH_CONFIG.audience,
    clientID: AUTH_CONFIG.clientId,
    domain: AUTH_CONFIG.domain,
    redirectUri: `${location.protocol}//${location.host}/callback`,
    responseType: "id_token token",
    scope: "openid name email scopes profile",
  });

  get isLoggedIn(): boolean {
    // Check whether the current time is past the
    // access token"s expiry time
    const expiresAt = JSON.parse(localStorage.getItem("expires_at")!);
    return (new Date().getTime() < expiresAt);
  }

  public getProfile = () => {
    try {
      const profileStr: string = localStorage.getItem("profile") || "";
      return JSON.parse(profileStr) || undefined;
    } catch (e) {
      return undefined;
    }
  }

  public getAccessToken = () => {
    return localStorage.getItem("access_token") || "";
  }

  public login = (nextUri: string) => {
    localStorage.setItem("returnUri", nextUri);
    this.auth0.authorize();
  }

  public ensureAuthentication = () => {
    if (!this.isLoggedIn) {
      this.login(history.location.pathname);
      return false;
    } else {
      return true;
    }
  }

  public handleAuthentication = (): void => {
    this.auth0.parseHash((e, result) => {
      if (result && result.accessToken && result.idToken) {
        this.setSession(result);
        const returnUri = localStorage.getItem("returnUri");
        if (returnUri) {
            localStorage.removeItem("returnUri");
          }
        history.replace(returnUri || "/");
      } else if (e) {
        history.replace("/");
        // tslint:disable-next-line:no-console
        console.error(e);
        alert(`Error: ${e.error}. Check the console for further details.`);
      }
    });
  }

  public setSession = (authResult: Auth0DecodedHash) => {
    const { accessToken, expiresIn, idToken, idTokenPayload } = authResult;
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify(expiresIn! * 1000 + new Date().getTime());
    localStorage.setItem("access_token", accessToken!);
    localStorage.setItem("id_token", idToken!);
    localStorage.setItem("expires_at", expiresAt);
    localStorage.setItem("profile", JSON.stringify(idTokenPayload));
  }

  public logout = () => {
    // Clear access token and ID token from local storage
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("profile");
    // navigate to the home route
    history.replace("/");
  }
}

export const WebAuthInstance = new WebAuthentication();
