import React from "react";
import { Route, Switch } from "react-router-dom";
import AppView from "../../appview/AppView";
import Callback from "../../callback/Callback";
import PageNotFound from "../../error/PageNotFound";
import ToolsHome from "../../home/ToolsHome";
import AboutUs from "../../instructional/AboutUs";
import ContactUs from "../../instructional/ContactUs";
import ProtectedRoute from "./ProtectedRoute";

export default class Routes extends React.Component {
    public render() {
        return(
          <div>
              <Switch>
                <Route path={["/", "/tools", "/home"]} exact component={ToolsHome} />
                <Route path="/callback" component={Callback}/>
                <Route path="/about" exact component={AboutUs} />
                <Route path="/contact-us" exact component={ContactUs} />
                <Route path="/page-not-found" exact component={PageNotFound}/>
                <ProtectedRoute path="/:appName" component={AppView} />
                <Route component={PageNotFound}/>
              </Switch>
          </div>
        );
    }
}
