import { shapes } from "@cimpress/react-components";
import React, {CSSProperties} from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import { CoamAuthorization } from "../../../auth/CoamAuthorization";
import AccessDenied from "../../error/AccessDenied";
import { WebAuthInstance } from "./../../../auth/WebAuthentication";

interface IProps {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    [key: string]: any;
}
interface IState {
    authorizing: boolean;
    accessDenied: boolean;
}

export default class ProtectedRoute extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            accessDenied : false,
            authorizing : true,
        };
    }

    public componentDidMount() {
        if (WebAuthInstance.ensureAuthentication()) {
            CoamAuthorization.authorizeUser()
            .then((isAuthorized) => {
                    this.setState({
                        ...this.state,
                        accessDenied: !isAuthorized,
                        authorizing: false,
                    });
            });
        }
    }

    public render() {
        const { Spinner } = shapes;
        const spinStyle: CSSProperties = {
            textAlign : "center",
        };
        if (this.state.authorizing) {
            return(
                <div className="container" style={spinStyle}>
                    <Spinner size="large"/>
                </div>
            );
        } else if (this.state.accessDenied) {
            return (<div className="container"> <AccessDenied /> </div>);
        } else {
            const {component: Component, ...rest} = this.props;
            return (<Route {...rest} component={Component}></Route>);
        }
    }
}
