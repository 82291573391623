import { FourOhFour } from "@cimpress/react-components";
import React, { Component } from "react";

export default class PageNotFound extends Component {
  public render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <FourOhFour
              message={
                <div>
                  Oops! Something has gone wrong and the page you were looking for could not be displayed. Try{" "}
                  <a href="/">the homepage</a>.
                </div>
              }
            />
          </div>
        </div>
      </div>
    );
  }
}
