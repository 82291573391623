import { CssLoader } from "@cimpress/react-components";
import React from "react";
import * as styles from "./App.scss";
import AppRouter from "./AppRouter";

const App: React.FC = () => {
  return (
    <CssLoader>
      <div className={styles.app}>
        <AppRouter></AppRouter>
      </div>
    </CssLoader>
  );
};
export default App;
