class PageTitleHandler {
    public defaultPageTitle = "VCS Internal Tools";
    private update?: (t: string) => void = undefined;

    public bindUpdateMethod(update: (t: string) => void) {
        this.update = update;
    }

    public updatePageTitle(title?: string) {
        if (typeof(this.update) === "function") {
            this.update(title ? title : this.defaultPageTitle);
        }
    }
}

const pageTitleHandler = new PageTitleHandler();

export default pageTitleHandler;
