import axios from "axios";
import IAppConfig from "../models/IAppConfig";

const appConfigJsonPath = "/static/data/appconfigs.json";

class AppConfigProvider {

    public async getList() {
        const configList: IAppConfig[] = await this.getConfigData();
        return configList.filter((d) => d.isActive);
    }

    public async get(key: string) {
        const configList: IAppConfig[] = await this.getConfigData();
        return configList.filter((d) => d.key === key)[0];
    }

    private async getConfigData(): Promise<IAppConfig[]> {
        const resp = await axios.get(appConfigJsonPath);
        const configList: IAppConfig[] = resp.data.configList;
        return configList;
    }
}

const appConfigProvider = new AppConfigProvider();

export default appConfigProvider;
