import React from "react";

export default class ContactUs extends React.Component {
    public render = () => {
        return (
            <div className="container">
                <div className="content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <h1> Contact Us </h1><br/>
                            <p>
                                Have any questions, feedback or comments? We'd love to hear
                                about them!
                            </p>
                            <p>
                                Email us at{" "}
                                <a href="mailto:zerogravity@vistaprint.com?Subject=Internal%20Tools%20Feedback" target="_top">zerogravity@vistaprint.com</a>.
                            </p>
                            <p>
                                We'll do our best to respond as soon as possible.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            );
        }
    }
