import React from "react";

export default class AboutUs extends React.Component {
    public render = () => {
        return (
            <div className="container">
                <div className="content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <h1> About Us </h1>
                            <br/>
                            <p>
                                This console is use by internal stakeholders
                                to help identify and access VCS tools and services
                                and find correlating documentation.
                            </p>
                            <p>
                                User access to applications and services is managed through{" "}
                                <a href="https://users.cimpress.io/">Access Management</a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            );
        }
    }
