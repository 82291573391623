import React from "react";
import { Router } from "react-router-dom";
import Header from "./components/shared/header/Header";
import Routes from "./components/shared/routes/Routes";
import history from "./utils/history";

export default class AppRouter extends React.Component {

  public render() {
    return (
      <Router history={history}>
        <Header></Header>
        <Routes></Routes>
      </Router>
    );
  }
}
