import fetchIntercept from "fetch-intercept";
import { WebAuthInstance } from "./WebAuthentication";
export function register() {
    // ((send) => {
    //     XMLHttpRequest.prototype.send = function(data) {
    //         const accessToken = WebAuthInstance.getAccessToken();
    //         /* injecting an access token (eg. accessToken) in the request headers before it gets sent */

    //         if (accessToken) { this.setRequestHeader("Authorization", `Bearer ${accessToken}`); }

    //         send.call(this, data);
    //     };
    // })(XMLHttpRequest.prototype.send);

    ((open) => {
        XMLHttpRequest.prototype.open =
        function(method: string, url: any , async: boolean = true, username?: string, password?: string) {
            const accessToken = WebAuthInstance.getAccessToken();
            /* injecting an access token (eg. accessToken) in the request headers before it gets sent */

            open.call(this, method, url, async, username, password);
            if (accessToken) { this.setRequestHeader("Authorization", `Bearer ${accessToken}`); }
        };
    })(XMLHttpRequest.prototype.open);

    fetchIntercept.register({
        request(url, config) {
            const accessToken = WebAuthInstance.getAccessToken();
            if (accessToken) {
                if (!config) { config = {}; }
                if (!config.headers) { config.headers = {}; }
                config.headers.Authorization = `Bearer ${accessToken}`;
            }
            return [url, config];
        },
    });
}
