import { shapes } from "@cimpress/react-components";
import React, { CSSProperties } from "react";
import { WebAuthInstance } from "../../auth/WebAuthentication";

const { Spinner } = shapes;

export default class Callback extends React.Component {
    public render = () => {

        if (/access_token|id_token|error/.test(window.location.hash)) {
            WebAuthInstance.handleAuthentication();
        }

        const spinStyle: CSSProperties = {
            textAlign : "center",
        };

        return (
            <div className="container" style={spinStyle}>
                <Spinner size="large"/>
            </div>
            );
        }
    }
