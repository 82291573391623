import { shapes } from "@cimpress/react-components";
import React, { CSSProperties } from "react";
import IAppConfig from "../../models/IAppConfig";
import AppConfigProvider from "../../providers/AppConfigProvider";
import history from "../../utils/history";

interface IState {
    appList: IAppConfig[];
}
export default class ToolsHome extends React.Component<{}, IState> {

    constructor(props: {}) {
        super(props);
        this.state = {
            appList: [],
        };
        AppConfigProvider.getList()
            .then((result) => {
                this.setState({
                    appList: result,
                });
            });
    }

    public render() {
        const gridStyle: CSSProperties = {
            display: "grid",
            gap: "30px 30px",
            gridTemplateColumns: "1fr 1fr 1fr",
        };
        return (
            <div className="container">
                <div className="content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <h1> Tools </h1>
                            <div className="card-deck" style={gridStyle}>
                                {this.renderList()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderList = () => {
        const { CardHeader } = shapes;
        const list = [];
        for (const i of this.state.appList) {
            list.push(
                <div key={i.key} className="card clickable" onClick={() => {this.onCardClick(i); }}>
                    <div className="card-header">
                        <h3>{i.details.title}</h3>
                        <CardHeader svgName={i.details.svgName} svgUrl={i.details.svgUrl}></CardHeader>
                    </div>
                    <div className="card-block">
                        {i.details.description}
                    </div>
                </div>);
        }
        return list;
    }

    private onCardClick = (app: IAppConfig) => {
        history.replace(`/${app.key}`);
    }
}
